<template>
  <div>
    <v-toolbar color="white" flat>
      <v-toolbar-title>Tablets and Devices</v-toolbar-title>
      <v-divider
              class="mx-2"
              inset
              vertical
      ></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
            :headers="headers"
            :items="items"
            dense
            disable-pagination
            hide-default-footer
    >

      <template v-slot:body="{ items }">
        <tbody>
        <tr :key="item.id" v-for="item in items">
          <td class="text-left">
            {{ item.name }}
          </td>
          <td class="text-left">
            {{ item.ident }}&nbsp;{{ item.serial }}
          </td>
          <td class="justify-center layout">
            <v-icon class="mr-2" small v-if="item.active">
              mdi-check-bold
            </v-icon>
          </td>
          <td class="text-center">{{ prettyDT(item.seen) }}</td>
          <td class="text-center">
            {{ item.timezone }}
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold">Total Devices: {{ items.length }}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </template>

    </v-data-table>
    <v-divider class="d-print-none"></v-divider>

    <v-row>
      <v-col>
        <v-btn @click="onRefreshClick" class="white--text mx-6" color="primary">
          <v-icon dark left>mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row>
      <v-col>
        To register a new device, click the button below to request a pin code.
        Then enter the pin code in the tablet.
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="onGetPinClick" class="white--text mx-6" color="primary">
          <v-icon dark left>mdi-refresh</v-icon>
          Get Pin Code
        </v-btn>
      </v-col>

      <v-col v-if="pinCode">
        <v-row>
          <div class="display-3 font-weight-regular">
            {{ pinCode.slice(0,3) }} &nbsp; {{ pinCode.slice(3) }}
          </div>
        </v-row>
        <v-row>
          This pin code expires in: {{ secsRemaining }} seconds
        </v-row>
      </v-col>
      <v-col v-if="pinCode">
        <v-progress-circular
                :rotate="-90"
                :size="80"
                :value="pctRemaining"
                :width="40"
                color="primary"
        >
        </v-progress-circular>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-alert :value="(errorMessage!='')" outlined type="error">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>

    <busy :message="busyMessage"></busy>
  </div>
</template>

<script>
  import Busy from './Busy'
  import axios from 'axios'
  import { format, parseISO } from 'date-fns'
  import { prettyDT } from './utils'

  export default {
    name: "Devices",
    components: {
      Busy
    },

    created () {
      console.log('Created:', this.$options.name)
      this.busyMessage = ''
      this.errorMessage = ''
      this.refresh()
    },
    beforeDestroy () {
      this.busyMessage = ''
      this.errorMessage = ''
      console.log('BeforeDestroy:', this.$options.name)
    },

    methods: {
      format,
      parseISO,
      prettyDT,

      getPin () {
        console.log('GET Pin Code')
        if (!this.$store.getters.loggedIn) {
          return
        }
        this.busyMessage = 'Loading...'

        axios.get('/device/pin')
            .then(res => {
              this.busyMessage = ''
              console.log('Pin:', res.data)
              this.timeStarting = Date.now()
              this.secsRemaining = res.data.seconds
              this.secsStarting = this.secsRemaining // save for % calcs
              // start timer
              this.timerID = setInterval(this.onTick, 250)
              this.onTick()
              this.pinCode = res.data.code // causes countdown to become visible
            })
            .catch(error => {
              console.log(error.toString())
              this.pinCode = ''
              this.busyMessage = ''
              clearInterval(this.timerID)
              this.showError('Error unable to get pin code: ' + error.toString())
            })
      },
      onTick () {
        let secs = this.secsStarting - (Date.now() - this.timeStarting) / 1000
        this.pctRemaining = 100 * secs / this.secsStarting // float value, smooth
        this.secsRemaining = Math.floor(secs) // int value, changes by the whole sec
        if (this.secsRemaining <= 0) {
          clearInterval(this.timerID)
          console.log("Pin expired:", this.pinCode)
          this.pinCode = ''
        }
      },
      onGetPinClick () {
        console.log("On get pin click")
        this.getPin()
      },
      onRefreshClick () {
        console.log("On refresh click")
        this.refresh()
      },
      refresh () {
        console.log('GET Devices')
        if (!this.$store.getters.loggedIn) {
          return
        }
        this.busyMessage = 'Loading...'

        axios.get('/device/list')
            .then(res => {
              this.busyMessage = ''
              console.log('Devices:', res.data)
              this.items = res.data
            })
            .catch(error => {
              console.log(error.toString())
              this.busyMessage = ''
              this.items = []
              this.showError('Error unable to get devices: ' + error.toString())
            })
      }
    },

    data () {
      return {
        busyMessage: '',
        errorMessage: '',

        // related to the pin code and expiration
        pinCode: '',
        pctRemaining: 0,
        secsRemaining: 0,
        secsStarting: 45, // avoid divide by zero
        timeStarting: null,
        timerID: null,

        // table for list of devices/tablets
        items: [],
        headers: [
          { text: 'Name', align: 'left', value: 'name' },
          { text: 'Ident', align: 'left', value: 'name' },
          { text: 'Active', align: 'center', value: 'active' },
          { text: 'Last seen', align: 'center', value: 'seen' },
          { text: 'Timezone', align: 'center', value: 'timezone' }
        ]
      }
    }
  }
</script>

<style scoped>

</style>
